/* eslint-disable max-len */
import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";

import SEO from "../components/Seo";
import BlogPage from "../components/template-parts/BlogPage";

function Infertilidade() {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "curso/banner09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const bannerPage = banner.childImageSharp.fluid;
  const content = {
    title: `Infertilidade`,
    featuredImage: bannerPage,
  };
  return (
    <>
      <SEO
        title={content.title}
        keywords="helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
        description=" A infertilidade é um problema que pode acometer até 25% dos casais em
        algum momento do seu relacionamento. Um problema desta magnitude
        requer atenção especial e várias alternativas de tratamento. A
        acupuntura pode ser um caminho para o tratamento desses casos,
        principalmente quando associada à medicação, fertilização e outras
        técnicas de reprodução assistida."
        type="article"
      />
      <BlogPage content={content}>
        <p>
          A infertilidade é um problema que pode acometer até 25% dos casais em algum momento do seu
          relacionamento. Um problema desta magnitude requer atenção especial e várias alternativas
          de tratamento. A acupuntura pode ser um caminho para o tratamento desses casos,
          principalmente quando associada à medicação, fertilização e outras técnicas de reprodução
          assistida. Os estudos mais recentes em ginecologia e acupuntura mostram que a acupuntura
          influencia os níveis sanguíneos de hormônios femininos e regulariza a circulação ovariana
          e uterina.&nbsp;
        </p>

        <h2>O que isso significa?&nbsp;</h2>

        <p>
          A infertilidade é um problema que pode acometer até 25% dos casais em algum momento do seu
          relacionamento. Um problema desta magnitude requer atenção especial e várias alternativas
          de tratamento. A acupuntura pode ser um caminho para o tratamento desses casos,
          principalmente quando associada à medicação, fertilização e outras técnicas de reprodução
          assistida. Os estudos mais recentes em ginecologia e acupuntura mostram que a acupuntura
          influencia os níveis sanguíneos de hormônios femininos e regulariza a circulação ovariana
          e uterina.&nbsp;
        </p>

        <p>
          Isso mostra que a acupuntura ajuda a normalizar os ciclos da mulher, aumentando o número
          de ciclos ovulatórios, e que ela ainda promove melhor circulação da parede do útero, o que
          aumenta as chances de gravidez.
        </p>

        <h2>Como a acupuntura age?</h2>

        <p>
          O mecanismo de ação da <Link to="/acupuntura">acupuntura</Link>, observado em vários
          experimentos, revela que após a colocação das agulhas ocorre liberação no corpo de
          β-endorfinas, que são opiódes (como a morfina) internos. A β-endorfina é responsável pela
          diminuição da dor, o que explica o uso da acupuntura nas cólicas menstruais e outras
          dores. Através da liberação dos opióides (β-endorfina) pelo sistema nervoso central
          pode-se regularizar o eixo hipotálamo-hipofisário-ovariano, que é responsável pela
          ovulação na mulher.
        </p>

        <p>
          Além da β-endorfina, sabe-se hoje que a acupuntura promove regulação dos níveis hormonais
          de LH, FSH, estrógeno e progesterona, que são responsáveis pelo ciclo menstrual e pela
          ovulação na mulher.&nbsp;
        </p>

        <p>
          Finalmente, observa-se o aumento da circulação local com o uso da acupuntura, o que
          proporciona melhor irrigação sanguínea do útero e dos ovários, facilitando a ovulação e a
          implantação do óvulo fecundado na parede do útero. Por exemplo, um estudo alemão
          demonstrou que a taxa de gravidez em mulheres submetidas à acupuntura durante a reprodução
          assistida (fertilização in vitro e injeção de esperma intra-citoplasmático) foi de 42,5%,
          um resultado estatisticamente superior ao das mulheres que fizeram a reprodução assistida
          sem a acupuntura (26,3%). Este resultado superior pode ser devido à inibição da motilidade
          uterina e à diminuição da pressão das artérias uterinas, que conjuntamente ajudariam no
          momento da implantação do óvulo fecundado no útero.
        </p>

        <p>
          A acupuntura pode ser usada também no tratamento da infertilidade masculina, melhorando a
          qualidade do esperma (quantidade de espermatozóides móveis), como foi observado em
          diversos estudos. Em um estudo em que 108 homens com oligospermia foram tratados com
          medicação apenas, ou com a mesma medicação combinada à acupuntura, o grupo tratado com
          acupuntura teve taxas significativamente superiores de normalização dos parâmetros do
          sêmen (74%), quando comparado ao grupo que recebeu apenas a medicação (52%).&nbsp;
        </p>

        <p>
          Além dos efeitos vistos acima a acupuntura promove relaxamento imediato e melhora o sono,
          a ansiedade e a depressão, fatores importantes que podem contribuir para o insucesso do
          tratamento da infertilidade.
        </p>

        <p>
          Os melhores casos para o tratamento de infertilidade e acupuntura são os das mulheres
          inférteis por fatores ovarianos, tais como os ovários policísiticos, e por fatores
          peritoniais como a endometriose, bem como dos homens com baixa contagem de
          espermatozóides. Pólipos, malformações uterinas e obstruções tubárias são causas que não
          podem ser tratadas pela acupuntura. Existe ainda um grupo onde não há causas aparentes
          para a infertilidade do casal. Neste caso, pode-se supôr que o fator emocional influencia
          mais este grupo que os outros. Para o tratamento do “fator emocional”, a acupuntura é
          capaz de reduzir os níveis de ansiedade que dificultam a gravidez.
        </p>

        <p>O uso da acupuntura durante a gestação.</p>

        <p>
          Durante a gestação não é possível fazer uso de muitas medicações e a acupuntura pode ser
          indicada para o tratamento de queixas como enjôo, dores de cabeça, depressão, lombalgia,
          fadiga e insônia, entre outras, sem comprometer a saúde da mãe ou do bebê.&nbsp;
        </p>
      </BlogPage>
    </>
  );
}

export default Infertilidade;
